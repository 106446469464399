import React, { useEffect, useState } from 'react';
import { getAllFilters, toggleFilterStatus, generateFilters, updateFilter } from '../apicalls/filters'; // Import updateFilter
import { isAuthenticated } from '../apicalls/auth';
import "./style/Filters.css"; // Scoped CSS file for Filters

const Filters = () => {
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const user = isAuthenticated();
  const { access_token } = user;
  const [editingFilter, setEditingFilter] = useState({ filterId: null, questionId: null });
  const [newFilterName, setNewFilterName] = useState('');

  useEffect(() => {
    const fetchFilters = async () => {
      const fetchedFilters = await getAllFilters(access_token);
      console.log("Fetched filters:", fetchedFilters);
      setFilters(fetchedFilters);
      setLoading(false);
    };
    fetchFilters();
  }, [access_token]);

  const handleToggleStatus = async (filterId, questionId, currentStatus) => {
    try {
      // Toggle the status by passing the questionId and status
      await toggleFilterStatus(filterId, questionId, !currentStatus, access_token);

      // Refresh the filters after the update
      const refreshedFilters = await getAllFilters(access_token);
      setFilters(refreshedFilters);
    } catch (error) {
      console.error("Error toggling filter status:", error);
    }
  };

  const handleGenerateFilters = async () => {
    try {
      setLoading(true);
      setMessage('');
      // Call the API to generate filters
      await generateFilters(access_token);

      // Fetch the latest filters
      const refreshedFilters = await getAllFilters(access_token);
      setFilters(refreshedFilters);

      setMessage('Filters have been successfully generated.');
    } catch (error) {
      console.error("Error generating filters:", error);
      setMessage('An error occurred while generating filters.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditFilterName = (filterId, questionId, currentFilterName) => {
    setEditingFilter({ filterId, questionId });
    setNewFilterName(currentFilterName);
  };

  const handleSaveFilterName = async (filterId, questionId) => {
    try {
      // Ensure the new filter name is at most 10 characters
      if (newFilterName.length > 15) {
        alert('Filter Name should be at most 15 characters.');
        return;
      }

      // Find the filter to update
      const filterToUpdate = filters.find(filter => filter.id === filterId);
      if (!filterToUpdate) {
        console.error("Filter not found");
        return;
      }

      // Update the filter_json
      const updatedAllowedFilters = filterToUpdate.filter_json.allowedFilters.map(allowedFilter => {
        if (allowedFilter.questionId === questionId) {
          return { ...allowedFilter, filterName: newFilterName };
        }
        return allowedFilter;
      });

      const updatedFilterJson = {
        ...filterToUpdate.filter_json,
        allowedFilters: updatedAllowedFilters,
      };

      // Call the API to update the filter
      await updateFilter(filterId, { filter_json: updatedFilterJson }, access_token);

      // Refresh the filters after the update
      const refreshedFilters = await getAllFilters(access_token);
      setFilters(refreshedFilters);

      // Reset editing state
      setEditingFilter({ filterId: null, questionId: null });
      setNewFilterName('');

      setMessage('Filter name updated successfully.');
    } catch (error) {
      console.error("Error updating filter name:", error);
      setMessage('An error occurred while updating the filter name.');
    }
  };

  const handleCancelEdit = () => {
    setEditingFilter({ filterId: null, questionId: null });
    setNewFilterName('');
  };

  if (loading) return <div>Loading...</div>;

  return (
    <main className="filters-container container">
      <div className="filters-title my-4">
        <h2>Manage Filters</h2>
        <button className="btn btn-primary" onClick={handleGenerateFilters}>
          Generate Latest Filters
        </button>
      </div>
      {message && <div className="alert alert-info">{message}</div>}

      {filters.map((filter) => (
        <div key={filter.id} className="filter-card mb-5">
          <h3 className="mb-3">{filter.filter_name}</h3>
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th scope="col">Filter Name</th>
                <th scope="col">Question Text</th> {/* Added Question Text column */}
                <th scope="col">Filter Type</th>
                <th scope="col">Options</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {filter.filter_json.allowedFilters.map((allowedFilter, index) => (
                <tr key={index}>
                  <td>
                    {editingFilter.filterId === filter.id && editingFilter.questionId === allowedFilter.questionId ? (
                      <input
                        type="text"
                        value={newFilterName}
                        onChange={(e) => setNewFilterName(e.target.value)}
                      />
                    ) : (
                      allowedFilter.filterName
                    )}
                  </td>
                  <td>{allowedFilter.questionText}</td> {/* Displayed Question Text */}
                  <td>{allowedFilter.filterType}</td>
                  <td>
                    {allowedFilter.options && allowedFilter.options.length > 0 ? (
                      <ul className="list-unstyled mb-0">
                        {allowedFilter.options.map((option, idx) => (
                          <li key={idx}>{option}</li>
                        ))}
                      </ul>
                    ) : (
                      <em>No options available</em>
                    )}
                  </td>
                  <td>
                    <span
                      className={`badge ${allowedFilter.status ? 'bg-success' : 'bg-secondary'}`}
                    >
                      {allowedFilter.status ? 'Enabled' : 'Disabled'}
                    </span>
                  </td>
                  <td>
                    {editingFilter.filterId === filter.id && editingFilter.questionId === allowedFilter.questionId ? (
                      <>
                        <button
                          className="btn btn-success btn-sm me-2"
                          onClick={() => handleSaveFilterName(filter.id, allowedFilter.questionId)}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary btn-sm me-2"
                          onClick={() =>
                            handleEditFilterName(filter.id, allowedFilter.questionId, allowedFilter.filterName)
                          }
                        >
                          Edit
                        </button>
                        <button
                          className={`btn ${allowedFilter.status ? 'btn-danger' : 'btn-success'} btn-sm`}
                          onClick={() =>
                            handleToggleStatus(filter.id, allowedFilter.questionId, allowedFilter.status)
                          }
                        >
                          {allowedFilter.status ? 'Deactivate' : 'Activate'}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </main>
  );
};

export default Filters;
