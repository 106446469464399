import React, { useEffect, useState, useCallback } from 'react';
import { getAllOptionGroups, deleteOptionGroup, addOptionGroup, updateOptionGroup } from "../apicalls/questions";
import OptionGroupForm from './OptionGroupForm';
import { isAuthenticated } from '../apicalls/auth';
import "../main/PopupForm/style/PopupForm.css";  // Ensure the path is correct based on your directory structure

function OptionGroups() {
    const [optionGroups, setOptionGroups] = useState([]);
    const [modalShow, setModalShow] = useState({ add: false, edit: false, delete: false });
    const [currentGroup, setCurrentGroup] = useState(null);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const user = isAuthenticated();

    // Load Option Groups
    const loadOptionGroups = useCallback(async () => {
        try {
            const response = await getAllOptionGroups(user.access_token);
            setOptionGroups(response || []);
        } catch (error) {
            console.error('Failed to load option groups:', error);
        }
    }, [user.access_token]);

    useEffect(() => {
        loadOptionGroups();
    }, [loadOptionGroups, modalShow]);

    // Add Option Group
    const handleAdd = async (groupDetails) => {
        console.log("🚀 ~ handleAdd ~ groupDetails:", groupDetails)
        try {
            await addOptionGroup(groupDetails, user.access_token);
            setModalShow({ ...modalShow, add: false });
            loadOptionGroups();
        } catch (error) {
            console.error('Error adding group:', error);
        }
    };

    // Edit Option Group
    const handleEdit = async (id, groupDetails) => {
        try {
            await updateOptionGroup(id, groupDetails, user.access_token);
            setModalShow({ ...modalShow, edit: false });
            loadOptionGroups();
        } catch (error) {
            console.error('Error updating group:', error);
        }
    };

    // Delete Option Group
    const handleDelete = async (id) => {
        try {
            await deleteOptionGroup(id, user.access_token);
            setModalShow({ ...modalShow, delete: false });
            loadOptionGroups();
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    // Toggle Group Expansion
    const toggleExpand = (id) => {
        setExpandedGroup(expandedGroup === id ? null : id);
    };

    return (
        <div className="container mt-3">
            <button className="btn btn-primary" onClick={() => setModalShow({ ...modalShow, add: true })}>Add Option Group</button>
            {optionGroups.map(group => (
                <div key={group.id} className="card mt-2">
                    <div className="card-header" onClick={() => toggleExpand(group.id)}>
                        <h5 className="mb-0">{group.option_group_name} {expandedGroup === group.id ? ' [-]' : ' [+]'}</h5>
                    </div>
                    {expandedGroup === group.id && (
                        <div className="card-body">
                            <ul>
                                {group.choices.map(choice => (
                                    <li key={choice.id}>{choice.option_choice_name} - {choice.description}</li>
                                ))}
                            </ul>
                            <button className="btn btn-info" onClick={() => {
                                setCurrentGroup(group);
                                setModalShow({ ...modalShow, edit: true });
                            }}>Edit</button>
                            <button className="btn btn-danger" onClick={() => handleDelete(group.id)}>Delete</button>
                        </div>
                    )}
                </div>
            ))}
            {modalShow.add && (
                <OptionGroupForm
                    group={null}
                    onSave={handleAdd}
                    onCancel={() => setModalShow({ ...modalShow, add: false })}
                />
            )}
            {modalShow.edit && currentGroup && (
                <OptionGroupForm
                    group={currentGroup}
                    onSave={handleEdit}
                    onCancel={() => setModalShow({ ...modalShow, edit: false })}
                />
            )}
        </div>
    );
}

export default OptionGroups;
