import Pagination from "@material-ui/lab/Pagination";
import "./style/pagination.css";

const Paginations = ({ perPage, total, paginate, currentPage }) => {
  const totalPages = Math.ceil(total / perPage);

  const handleChange = (event, value) => {
    paginate(value);
  };

  return (
    <Pagination
      count={totalPages}
      variant="outlined"
      shape="rounded"
      size="medium"
      page={currentPage}
      onChange={handleChange}
      className="pagination"
    />
  );
};

export default Paginations;
