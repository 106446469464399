import { API } from "../../constAPI";

// Fetch all filters
export const getAllFilters = async (token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to fetch filters');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching filters:', error);
    return { error: error.message };
  }
};

// Fetch a specific filter by ID
export const getFilterById = async (id, token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to fetch filter');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching filter:', error);
    return { error: error.message };
  }
};

// Add a new filter
export const addFilter = async (filterData, token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to add filter');
    }
    return response.json();
  } catch (error) {
    console.error('Error adding filter:', error);
    return { error: error.message };
  }
};

// Update an existing filter
export const updateFilter = async (id, filterData, token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to update filter');
    }
    return response.json();
  } catch (error) {
    console.error('Error updating filter:', error);
    return { error: error.message };
  }
};

// Delete a filter by ID
export const deleteFilter = async (id, token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 204) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to delete filter');
    }
  } catch (error) {
    console.error('Error deleting filter:', error);
    return { error: error.message };
  }
};

// Toggle filter status (enable/disable)
export const toggleFilterStatus = async (filterId, questionId, status, token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/${filterId}/status`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ questionId, status }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to update filter status');
    }
    return response.json();
  } catch (error) {
    console.error('Error toggling filter status:', error);
    return { error: error.message };
  }
};
// Fetch dynamic filters based on questions
// Fetch dynamic filters based on questions
export const getDynamicFilters = async (token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/dynamic`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to fetch dynamic filters');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching dynamic filters:', error);
    return { error: error.message };
  }
};

export const generateFilters = async (token) => {
  try {
    const response = await fetch(`${API}/api/admin/filters/generate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to generate filters');
    }
    return response.json();
  } catch (error) {
    console.error('Error generating filters:', error);
    return { error: error.message };
  }
};


