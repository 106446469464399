// Spinner.js
import React from 'react';

const Spinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <div className="spinner-border text-primary" role="status" aria-label="Loading">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);

export default Spinner;
