import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import "../main/PopupForm/style/PopupForm.css";  // Ensure the correct path to the CSS file

function OptionGroupForm({ group, onSave, onCancel }) {
    const [groupName, setGroupName] = useState('');
    const [choices, setChoices] = useState([]);

    // Pre-populate form when editing
    useEffect(() => {
        if (group) {
            setGroupName(group.option_group_name);
            setChoices(group.choices || []);
        } else {
            setGroupName('');
            setChoices([]);
        }
    }, [group]);

    // Add a new choice field
    const handleAddChoice = () => {
        setChoices([...choices, { id: Date.now(), option_choice_name: '', description: '', sort_order: choices.length + 1 }]);
    };

    // Update a specific choice field
    const handleChoiceChange = (index, field, value) => {
        const newChoices = choices.map((choice, i) => i === index ? { ...choice, [field]: value } : choice);
        setChoices(newChoices);
    };

    // Remove a choice field
    const handleRemoveChoice = (index) => {
        setChoices(choices.filter((_, i) => i !== index));
    };

    // Validate that choices have names before submitting
    const validateChoices = () => {
        return choices.every(choice => choice.option_choice_name.trim() !== '');
    };

    // Submit form
    const handleSubmit = (e) => {
        e.preventDefault();
        if (groupName.trim() === '') {
            alert('Group name is required');
            return;
        }
        if (!validateChoices()) {
            alert('All choices must have a name');
            return;
        }

        // Log data to check what is being passed
        console.log('Saving group:', { option_group_name: groupName, choices });
        console.log('group group:', group);
        const choicesWithoutIds = choices.map(({ id, ...rest }) => rest);
        if (!group) {
            console.log("new add")
            onSave({ option_group_name: groupName, choices:choicesWithoutIds });
            return;
        }
        console.log("new edit")
        onSave(group ? group.id : null, { option_group_name: groupName, choices });
    };

    return (
        <div className="popup">
            <div className="popup-inner">
                <div className="close-btn" onClick={onCancel}>
                    <GrClose />
                </div>
                <h1>{group ? "Edit" : "Add"} Option Group</h1>
                <form onSubmit={handleSubmit} className="container">
                    <div className="form-content">
                        <label>Group Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <h4>Choices:</h4>
                        {choices.map((choice, index) => (
                            <div key={choice.id} className="input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Option Name"
                                    value={choice.option_choice_name}
                                    onChange={(e) => handleChoiceChange(index, 'option_choice_name', e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Description"
                                    value={choice.description}
                                    onChange={(e) => handleChoiceChange(index, 'description', e.target.value)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-danger" type="button" onClick={() => handleRemoveChoice(index)}>Remove</button>
                                </div>
                            </div>
                        ))}
                        <button className="btn btn-secondary" type="button" onClick={handleAddChoice}>Add Choice</button>
                    </div>
                    <div className="form-content">
                        <button type="submit" className="btn btn-primary">Save Group</button>
                        <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default OptionGroupForm;
