// UserAnswers.js
import React, { useEffect, useState, useCallback } from 'react';
import { getAllAnswers } from '../apicalls/questions';
import { isAuthenticated } from '../apicalls/auth';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Spinner from './Spinner.js'; // Ensure the path is correct

const UserAnswers = () => {
    const [userAnswers, setUserAnswers] = useState([]);
    const [expandedUserId, setExpandedUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const { access_token } = isAuthenticated();
    
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    
    // **Input States**: Controlled components for filter inputs
    const [searchInput, setSearchInput] = useState("");
    const [skillLevelInput, setSkillLevelInput] = useState("");
    const [locationInput, setLocationInput] = useState("");
    const [cityInput, setCityInput] = useState("");
    const [stateInput, setStateInput] = useState("");
    const [zipInput, setZipInput] = useState("");

    // **Applied Filters**: Filters used to fetch data
    const [filters, setFilters] = useState({
        search: "",
        skillLevel: "",
        location: "",
        city: "",
        state: "",
        zip: ""
    });

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    // **Debounced functions are removed to allow manual control via "Apply Filters"**

    useEffect(() => {
        const fetchUserAnswers = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await getAllAnswers(access_token, { 
                    perPage, 
                    page, 
                    ...filters // Spread applied filters
                });

                if (response.error) {
                    throw new Error(response.error);
                }

                setUserAnswers(response.users);
                setTotalPages(response.pagination.totalPages);
                setTotalUsers(response.pagination.totalUsers);
            } catch (err) {
                console.error("Error fetching user answers:", err);
                setError("Failed to load user answers. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchUserAnswers();
    }, [access_token, page, perPage, filters]); // Depend on applied filters

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedUserAnswers = React.useMemo(() => {
        let sortableUsers = [...userAnswers];
        if (sortConfig.key !== null) {
            sortableUsers.sort((a, b) => {
                const aKey = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
                const bKey = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';
                if (aKey < bKey) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aKey > bKey) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [userAnswers, sortConfig]);

    // **Handle Input Changes**: Update input state variables
    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSkillLevelChange = (e) => {
        setSkillLevelInput(e.target.value);
    };

    const handleLocationChange = (e) => {
        setLocationInput(e.target.value);
    };

    const handleCityChange = (e) => {
        setCityInput(e.target.value);
    };

    const handleStateChange = (e) => {
        setStateInput(e.target.value);
    };

    const handleZipChange = (e) => {
        setZipInput(e.target.value);
    };

    const toggleExpand = (userId) => {
        setExpandedUserId(prevId => (prevId === userId ? null : userId));
    };

    const handlePerPageChange = (e) => {
        setPerPage(parseInt(e.target.value, 10));
        setPage(1); // Reset to first page on perPage change
    };

    const handlePreviousPage = () => {
        setPage(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setPage(prev => Math.min(prev + 1, totalPages));
    };

    // **Apply Filters Function**
    const applyFilters = () => {
        setFilters({
            search: searchInput.trim(),
            skillLevel: skillLevelInput,
            location: locationInput.trim(),
            city: cityInput.trim(),
            state: stateInput.trim(),
            zip: zipInput.trim()
        });
        setPage(1); // Reset to first page on filter apply
    };

    // **Reset Filters Function**
    const resetFilters = () => {
        setSearchInput("");
        setSkillLevelInput("");
        setLocationInput("");
        setCityInput("");
        setStateInput("");
        setZipInput("");

        setFilters({
            search: "",
            skillLevel: "",
            location: "",
            city: "",
            state: "",
            zip: ""
        });
        setPage(1); // Reset to first page on filter reset
    };

    if (loading) return <Spinner />; // Use the Spinner component

    return (
        <main className="container-fluid mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                <h2>User Answers</h2>

                {/* Page Size Selector */}
                <div className="form-group mb-2">
                    <label htmlFor="pageSize" className="mr-2">Page Size:</label>
                    <select 
                        id="pageSize" 
                        className="form-control"
                        value={perPage} 
                        onChange={handlePerPageChange}
                        aria-label="Select page size"
                    >
                        {[10, 20, 50, 100].map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Search and Filter Fields */}
            <div className="d-flex flex-wrap mb-3">
                {/* Search by Name */}
                <div className="form-group mr-3 mb-2 flex-fill min-width-200">
                    <label htmlFor="search">Search by Name:</label>
                    <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchInput}
                        onChange={handleSearchChange}
                        placeholder="Enter name"
                        aria-label="Search by name"
                    />
                </div>

                {/* Search by Skill Level */}
                <div className="form-group mr-3 mb-2 flex-fill min-width-200">
                    <label htmlFor="skillLevel">Skill Level:</label>
                    <select
                        id="skillLevel"
                        className="form-control"
                        value={skillLevelInput}
                        onChange={handleSkillLevelChange}
                        aria-label="Filter by skill level"
                    >
                        <option value="">All Levels</option>
                        <option value="Beginner">Beginner (NTRP 2.5)</option>
                        <option value="Advanced Beginner">Advanced Beginner (NTRP 3.0)</option>
                        <option value="Intermediate">Intermediate (NTRP 3.5)</option>
                        <option value="Advanced">Advanced (NTRP 4.0)</option>
                    </select>
                </div>

                {/* Search by Location */}
                <div className="form-group mr-3 mb-2 flex-fill min-width-200">
                    <label htmlFor="location">Search by Location:</label>
                    <input
                        type="text"
                        id="location"
                        className="form-control"
                        value={locationInput}
                        onChange={handleLocationChange}
                        placeholder="Enter location"
                        aria-label="Search by location"
                    />
                </div>

                {/* Filter by City */}
                <div className="form-group mr-3 mb-2 flex-fill min-width-200">
                    <label htmlFor="city">City:</label>
                    <input
                        type="text"
                        id="city"
                        className="form-control"
                        value={cityInput}
                        onChange={handleCityChange}
                        placeholder="Enter city"
                        aria-label="Filter by city"
                    />
                </div>

                {/* Filter by State */}
                <div className="form-group mr-3 mb-2 flex-fill min-width-200">
                    <label htmlFor="state">State:</label>
                    <input
                        type="text"
                        id="state"
                        className="form-control"
                        value={stateInput}
                        onChange={handleStateChange}
                        placeholder="Enter state"
                        aria-label="Filter by state"
                    />
                </div>

                {/* Filter by Zip Code */}
                <div className="form-group mb-2 flex-fill min-width-200">
                    <label htmlFor="zip">Zip Code:</label>
                    <input
                        type="text"
                        id="zip"
                        className="form-control"
                        value={zipInput}
                        onChange={handleZipChange}
                        placeholder="Enter zip code"
                        aria-label="Filter by zip code"
                    />
                </div>
            </div>

            {/* Apply and Reset Filter Buttons */}
            <div className="d-flex mb-4">
                <button 
                    className="btn btn-primary mr-3"
                    onClick={applyFilters}
                    aria-label="Apply Filters"
                >
                    Apply Filters
                </button>
                <button 
                    className="btn btn-secondary"
                    onClick={resetFilters}
                    aria-label="Reset Filters"
                >
                    Reset Filters
                </button>
            </div>

            {/* Error Message */}
            {error && <div className="alert alert-danger">{error}</div>}

            {/* User Answers Table */}
            <div className="table-responsive">
                {userAnswers.length > 0 ? (
                    <table className="table table-hover table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th 
                                    scope="col" 
                                    onClick={() => handleSort('userId')} 
                                    style={{ cursor: 'pointer' }}
                                    className={sortConfig.key === 'userId' ? 'sorted' : ''}
                                    aria-sort={sortConfig.key === 'userId' ? sortConfig.direction : 'none'}
                                >
                                    User ID {sortConfig.key === 'userId' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                </th>
                                <th 
                                    scope="col" 
                                    onClick={() => handleSort('full_name')} 
                                    style={{ cursor: 'pointer' }}
                                    className={sortConfig.key === 'full_name' ? 'sorted' : ''}
                                    aria-sort={sortConfig.key === 'full_name' ? sortConfig.direction : 'none'}
                                >
                                    Full Name {sortConfig.key === 'full_name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                </th>
                                <th 
                                    scope="col" 
                                    onClick={() => handleSort('email')} 
                                    style={{ cursor: 'pointer' }}
                                    className={sortConfig.key === 'email' ? 'sorted' : ''}
                                    aria-sort={sortConfig.key === 'email' ? sortConfig.direction : 'none'}
                                >
                                    Email {sortConfig.key === 'email' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                </th>
                                <th 
                                    scope="col" 
                                    onClick={() => handleSort('phone')} 
                                    style={{ cursor: 'pointer' }}
                                    className={sortConfig.key === 'phone' ? 'sorted' : ''}
                                    aria-sort={sortConfig.key === 'phone' ? sortConfig.direction : 'none'}
                                >
                                    Phone {sortConfig.key === 'phone' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                </th>
                                <th 
                                    scope="col" 
                                    onClick={() => handleSort('location')} 
                                    style={{ cursor: 'pointer' }}
                                    className={sortConfig.key === 'location' ? 'sorted' : ''}
                                    aria-sort={sortConfig.key === 'location' ? sortConfig.direction : 'none'}
                                >
                                    Address {sortConfig.key === 'location' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                                </th>
                                <th scope="col">Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedUserAnswers.map(user => (
                                <React.Fragment key={user.userId}>
                                    <tr onClick={() => toggleExpand(user.userId)} style={{ cursor: 'pointer' }}>
                                        <td>{user.userId}</td>
                                        <td>{user.full_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.location || 'N/A'}</td> {/* Display Address */}
                                        <td>{expandedUserId === user.userId ? <FiChevronUp /> : <FiChevronDown />}</td>
                                    </tr>
                                    {expandedUserId === user.userId && (
                                        <tr>
                                            <td colSpan="6"> {/* Adjust colspan to match new table structure */}
                                                <div className="p-3">
                                                    <p>
                                                        <strong>Profile Picture:</strong> 
                                                        {user.profile_picture ? (
                                                            <img src={user.profile_picture} alt={`${user.full_name}'s profile`} width="50" className="rounded-circle ml-2" />
                                                        ) : (
                                                            ' No Image'
                                                        )}
                                                    </p>
                                                    
                                                    {/* User Answers */}
                                                    <div>
                                                        <strong>Answers:</strong>
                                                        {user.answers && user.answers.length > 0 ? (
                                                            <ul>
                                                                {user.answers.map((answer, index) => (
                                                                    <li key={index}>
                                                                        <strong>Q:</strong> {answer.questionText} <br />
                                                                        {answer.answerText ? (
                                                                            <>
                                                                                <strong>A:</strong> {answer.answerText}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <strong>A:</strong> 
                                                                                <ul>
                                                                                    {answer.answers.map((multiAnswer, i) => (
                                                                                        <li key={i}>{multiAnswer}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </>
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No answers available</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No user answers available</p>
                )}
            </div>

            {/* Apply and Reset Filter Buttons */}
            {/* (Moved above in the UI) */}

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center">
                <button 
                    className="btn btn-primary"
                    onClick={handlePreviousPage}
                    disabled={page === 1}
                    aria-label="Previous Page"
                >
                    Previous Page
                </button>
                <span>Page {page} of {totalPages} (Total Users: {totalUsers})</span>
                <button 
                    className="btn btn-primary"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    aria-label="Next Page"
                >
                    Next Page
                </button>
            </div>
        </main>
    );     
};

export default UserAnswers;
