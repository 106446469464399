// src/components/main/MapComponent.js

import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapComponent.css';
import { MAPBOX_TOKEN } from "../../constAPI"; // Ensure this is correctly imported

mapboxgl.accessToken = MAPBOX_TOKEN; // Use environment variables for security

function MapComponent({
  personalLocations = [],
  courtLocations = [],
  allLocations = [], // Receives all locations when "Show All Locations" is enabled
  radius = 6, // in miles
  onMapClick, // Callback to handle map clicks
  center,
}) {
  
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [currentCenter, setCurrentCenter] = useState([-75.343, 39.984]); // [longitude, latitude]
  const [mapLoaded, setMapLoaded] = useState(false);
  const [locationIndex, setLocationIndex] = useState(0);
   // Fly to the new center location whenever the `center` prop changes
   useEffect(() => {
    if (center && mapLoaded && mapRef.current) {
      
      mapRef.current.flyTo({
        center: [center.longitude, center.latitude],
        zoom: 10,
        essential: true,
      });
      
    }
  }, [center, mapLoaded]);
  useEffect(() => {
    if (mapLoaded && center) {
      drawCircle([center.longitude, center.latitude], radius);
    }
  }, [center, radius, mapLoaded]);
  // Function to draw or update the circle
  const drawCircle = (center, radiusInMiles) => {
    if (!mapRef.current) return;

    // Convert radius from miles to kilometers
    const radiusInKm = radiusInMiles * 1.60934;

    // Create or update the circle
    const circle = turf.circle(center, radiusInKm, { steps: 50, units: 'kilometers' });

    if (mapRef.current.getSource('circle')) {
      mapRef.current.getSource('circle').setData(circle);
    } else {
      mapRef.current.addSource('circle', {
        type: 'geojson',
        data: circle,
      });

      mapRef.current.addLayer({
        id: 'circle',
        type: 'fill',
        source: 'circle',
        layout: {},
        paint: {
          'fill-color': '#007cbf',
          'fill-opacity': 0.3,
        },
      });
    }
  };

  // Function to add markers to the map
// Function to add markers to the map
const addMarkers = (locations, color) => {
  if (Array.isArray(locations)) {
    locations.forEach(location => {
      if (
        location.longitude &&
        location.latitude &&
        !isNaN(parseFloat(location.longitude)) &&
        !isNaN(parseFloat(location.latitude))
      ) {
        const coordinatesHTML = location.coordinates
          ? `<a href="https://www.google.com/maps/search/?api=1&query=${location.coordinates.latitude},${location.coordinates.longitude}" target="_blank" rel="noopener noreferrer">${location.coordinates.latitude.toFixed(6)}, ${location.coordinates.longitude.toFixed(6)}</a>`
          : "N/A";
        
        new mapboxgl.Marker({ color })
          .setLngLat([parseFloat(location.longitude), parseFloat(location.latitude)])
          .setPopup(
            new mapboxgl.Popup().setHTML(`
              <div>
                <h3>${location.player_name || 'N/A'}</h3>
                <p><strong>LocationId:</strong> ${location.location_id || 'N/A'}</p>
                <p><strong>Email:</strong> ${location.email || 'N/A'}</p>
                <p><strong>Location:</strong> ${location.location || 'N/A'}</p>
                <p><strong>Location:</strong> ${location.location_type || 'N/A'}</p>
                <p><strong>Skill Level:</strong> ${location.skill_level || 'N/A'}</p>
                <p><strong>Looking For:</strong> ${location.looking_for ? location.looking_for.join(", ") : "N/A"}</p>
                <p><strong>Coordinates:</strong> ${coordinatesHTML}</p>
              </div>
            `)
          )
          .addTo(mapRef.current);
      } else {
        console.warn('Invalid location data:', location);
      }
    });
  }
};
  // Function to find and fly to the nearest location
  const flyToNearestLocation = () => {
    if (!allLocations.length) return;

    const nearestLocation = allLocations.reduce((closest, location) => {
      const distance = turf.distance(
        turf.point(currentCenter),
        turf.point([location.longitude, location.latitude]),
        { units: 'miles' }
      );

      return distance < closest.distance
        ? { location, distance }
        : closest;
    }, { location: null, distance: Infinity });

    if (nearestLocation.location && mapRef.current) {
      mapRef.current.flyTo({
        center: [nearestLocation.location.longitude, nearestLocation.location.latitude],
        zoom: 10,
        essential: true,
      });
    }
  };

  // Function to get and fly to the user's current location
  const goToCurrentLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const currentPosition = [longitude, latitude];
        setCurrentCenter(currentPosition);

        if (mapRef.current) {
          mapRef.current.flyTo({
            center: currentPosition,
            zoom: 12,
            essential: true,
          });
        }
      },
      () => {
        alert("Unable to retrieve your location.");
      }
    );
  };

   // Function to fly to the next location in the list
   const flyToNextLocation = () => {
    if (!allLocations.length) return;

    // Move to the next location, or wrap around if at the end of the list
    const nextIndex = (locationIndex + 1) % allLocations.length;
    const nextLocation = allLocations[nextIndex];

    if (nextLocation && mapRef.current) {
      mapRef.current.flyTo({
        center: [nextLocation.longitude, nextLocation.latitude],
        zoom: 10,
        essential: true,
      });
    }

    setLocationIndex(nextIndex);
  };



  // Initialize the map
  useEffect(() => {
    if (!mapboxgl.accessToken) {
      console.error("Mapbox access token is required.");
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center:[center?.longitude || -75.343, center?.latitude || 39.984],

      zoom: 10,
    });

    // Add navigation controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Handle map load
    mapRef.current.on('load', () => {
      setMapLoaded(true);
      drawCircle(currentCenter, radius);
      addMarkers(personalLocations, 'blue'); // Blue for personal locations
      addMarkers(courtLocations, 'red'); // Red for court locations
      addMarkers(allLocations, 'green'); // Green for all locations
    });

    // Handle map clicks to set new center and draw circle
    mapRef.current.on('click', (e) => {
      const { lng, lat } = e.lngLat;
      const newCenter = [lng, lat];
      setCurrentCenter(newCenter);
      drawCircle(newCenter, radius);
      if (onMapClick) {
        onMapClick({ longitude: lng, latitude: lat });
      }
    });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []); // Run once on mount

  // Update circle and markers when dependencies change
  useEffect(() => {
    if (!mapLoaded) return;

    drawCircle(currentCenter, radius);

    // Clear existing markers
    removeAllMarkers();

    // Add markers based on current toggles
    if (personalLocations.length > 0) {
      addMarkers(personalLocations, 'blue');
    }
    if (courtLocations.length > 0) {
      addMarkers(courtLocations, 'red');
    }
    if (allLocations.length > 0) {
      addMarkers(allLocations, 'green');
    }
  }, [currentCenter, radius, personalLocations, courtLocations, allLocations, mapLoaded]);

  // Function to remove all markers from the map
  const removeAllMarkers = () => {
    const markers = document.getElementsByClassName('mapboxgl-marker');
    while (markers[0]) {
      markers[0].parentNode.removeChild(markers[0]);
    }
  };

  return (
    <div className="map-component" style={{ width: '100%', height: '500px', position: 'relative' }}>
      {/* Map Container */}
      <div
        ref={mapContainerRef}
        className="map-box"
        style={{ width: '100%', height: '100%' }}
      />
  
      {/* Button Container */}
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          display: 'flex',
          gap: '5px', // Space between buttons
          zIndex: 1,
        }}
      >
        {/* Fly to Next Location Button */}
        <button
          onClick={flyToNextLocation}
          style={{
            padding: '5px 10px',
            backgroundColor: '#007cbf',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Next
        </button>
  
        {/* Fly to Nearest Location Button */}
        <button
          onClick={flyToNearestLocation}
          style={{
            padding: '5px 10px',
            backgroundColor: '#007cbf',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Nearest
        </button>
  
        {/* Go to Current Location Button */}
        <button
          onClick={goToCurrentLocation}
          style={{
            padding: '5px 10px',
            backgroundColor: '#4CAF50',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Current
        </button>
      </div>
    </div>
  );
  
  
}

export default MapComponent;
