import React, { useEffect, useState } from 'react';
import { FiEdit, FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import QuestionForm from './PopupForm/QuestionForm';
import DeletePopup from './PopupForm/DeletePopup';
import Paginations from './Paginations';
import OptionGroups from './OptionGroups';
import { getAllQuestions } from '../apicalls/questions';
import { isAuthenticated } from '../apicalls/auth';
import debounce from 'lodash.debounce';

function Questions() {
    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0); // Store total number of questions
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10); // Default value for perPage
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [expandedId, setExpandedId] = useState(null);
    const [modalShow, setModalShow] = useState({ add: false, edit: false, delete: false });
    const user = isAuthenticated();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getAllQuestions(user.access_token, perPage, currentPage, search);
            setQuestions(response.questions); // Set the questions from the API
            setTotalQuestions(response.total); // Set the total number of questions from the API
        };
        fetchData();
    }, [perPage, search, currentPage, user.access_token, modalShow]);

    const handleSearch = debounce((e) => setSearch(e.target.value), 500);
    const paginate = (number) => setCurrentPage(number);

    // Handle the change in the per-page value
    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(1); // Reset to the first page when perPage changes
    };

    // Handle successful deletion
    const handleDeleteSuccess = () => {
        setQuestions(questions.filter(q => q.id !== currentQuestion.id));
        setTotalQuestions(totalQuestions - 1); // Update the total questions count
        setCurrentQuestion(null); // Clear the current question
    };

    const toggleExpand = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <main className="container-fluid mt-3">
            <OptionGroups />
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1>Questions</h1>
                <button className="btn btn-primary" onClick={() => setModalShow({ ...modalShow, add: true })}>Add Question +</button>
            </div>
            {modalShow.add && (
                <QuestionForm action="Add" question={null} setPopup={() => setModalShow({ ...modalShow, add: false })} />
            )}
            <div className="mb-3 d-flex align-items-center">
                <input
                    className="form-control w-25"
                    type="text"
                    onChange={handleSearch}
                    placeholder="Search"
                />
                <div className="ml-3">
                    <label htmlFor="perPage">Items per page: </label>
                    <select
                        id="perPage"
                        className="form-select ml-2"
                        value={perPage}
                        onChange={handlePerPageChange}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </div>
            <h2>Active Questions</h2>
            <div className="table-responsive">
                {renderQuestionsTable(questions.filter(q => q.status))}
            </div>
            <h2>Inactive Questions</h2>
            <div className="table-responsive">
                {renderQuestionsTable(questions.filter(q => !q.status))}
            </div>
            {search === "" && (
                <Paginations
                    perPage={perPage}
                    total={totalQuestions}  // Use total questions count for pagination
                    paginate={paginate}
                    currentPage={currentPage}
                />
            )}
            {modalShow.edit && (
                <QuestionForm action="Edit" question={currentQuestion} setPopup={() => setModalShow({ ...modalShow, edit: false })} />
            )}
            {modalShow.delete && (
                <DeletePopup
                    question_id={currentQuestion?.id}  // Pass the question ID
                    setPopup={() => setModalShow({ ...modalShow, delete: false })}
                    onDeleteSuccess={handleDeleteSuccess}  // Handle successful deletion
                />
            )}
        </main>
    );

    function renderQuestionsTable(questionsSubset) {
        return (
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Question Text</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Details</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {questionsSubset.length > 0 ? questionsSubset.map(question => (
                        <React.Fragment key={question.id}>
                            <tr onClick={() => toggleExpand(question.id)} style={{ cursor: 'pointer' }}>
                                <td>{question.question_text}</td>
                                <td>{question.questionType}</td>
                                <td>{question.status ? 'Active' : 'Inactive'}</td>
                                <td>{expandedId === question.id ? <FiChevronUp /> : <FiChevronDown />}</td>
                                <td>
                                    <FiEdit className="text-primary" onClick={(e) => {
                                        e.stopPropagation();
                                        setCurrentQuestion(question);
                                        setModalShow({ ...modalShow, edit: true });
                                    }} />
                                </td>
                                <td>
                                    <FiTrash className="text-danger" onClick={(e) => {
                                        e.stopPropagation();
                                        setCurrentQuestion(question);
                                        setModalShow({ ...modalShow, delete: true });
                                    }} />
                                </td>
                            </tr>
                            {expandedId === question.id && (
                                <tr>
                                    <td colSpan="6">
                                        <div>
                                            <p><strong>Subtext:</strong> {question.question_subtext || "None"}</p>
                                            <p><strong>Option Group:</strong> {question.optiongroupdetails?.name || "No group"}</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    )) : (
                        <tr>
                            <td colSpan="6">No questions available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default Questions;
