// src/components/main/PlayerLocationSearch.js

import React, { useEffect, useState, useCallback } from "react";
import "./style/PlayerLocationSearch.css";
import { getPlayerLocationsInRadius } from "../apicalls/locations";
import debounce from "lodash.debounce";
import MapComponent from "./MapComponent.js";
import { isAuthenticated } from "../apicalls/auth";
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_TOKEN } from "../../constAPI"; // Ensure this is correctly imported

mapboxgl.accessToken = MAPBOX_TOKEN; // Use environment variables for security

function PlayerLocationSearch() {
  const [locations, setLocations] = useState({
    personalLocations: [],
    courtLocations: [],
  });
  const [allLocations, setAllLocations] = useState([]);
  const [total, setTotal] = useState(0);
  const [radius, setRadius] = useState(6); // in miles
  const [search, setSearch] = useState("");
  const [center, setCenter] = useState(null); // { latitude, longitude }
  const [showAllLocations, setShowAllLocations] = useState(false);
  const [showPersonalLocations, setShowPersonalLocations] = useState(false);
  const [showCourtLocations, setShowCourtLocations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = isAuthenticated();

  const fetchLocationsInRadius = async (latitude, longitude, radius, filter = 'all') => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const data = await getPlayerLocationsInRadius({
        latitude,
        longitude,
        radius,
        token: user.access_token,
        filter,
      });

      if (data) {
        setLocations({
          personalLocations: data.data.personalLocations || [],
          courtLocations: data.data.courtLocations || [],
        });
        setAllLocations(data.allLocations || []);
        setTotal(data.allLocations.length || 0);
      } else {
        setLocations({
          personalLocations: [],
          courtLocations: [],
        });
        setAllLocations([]);
        setTotal(0);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setErrorMessage("Unable to fetch locations. Please try again later.");
      setLocations({
        personalLocations: [],
        courtLocations: [],
      });
      setAllLocations([]);
      setTotal(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // On mount, get current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ latitude, longitude });
          // Fetch initial locations
          fetchLocationsInRadius(latitude, longitude, radius);
        },
        (error) => {
          console.error("Geolocation error:", error);
          // Optionally set a default center
          const defaultCenter = { latitude: 39.984, longitude: -75.343 };
          setCenter(defaultCenter);
          fetchLocationsInRadius(defaultCenter.latitude, defaultCenter.longitude, radius);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
      // Set default center
      const defaultCenter = { latitude: 39.984, longitude: -75.343 };
      setCenter(defaultCenter);
      fetchLocationsInRadius(defaultCenter.latitude, defaultCenter.longitude, radius);
    }
  }, []); // Empty dependency array to run once on mount

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const handleSearch = async () => {
    if (!search) return;
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search)}.json`,
        { params: { access_token: mapboxgl.accessToken, limit: 1 } }
      );
      console.log("🚀 ~ handleSearch ~ response:", response.data.features[0])
      const feature = response.data.features[0];
      if (feature) {
        const [lng, lat] = feature.center;
        setCenter({ latitude: lat, longitude: lng }); // Update the center to fly to the searched location
      } else {
        alert("Location not found. Please try a different name.");
      }
    } catch (error) {
      console.error("Error searching location:", error);
    }
  };

  const handleRadiusChange = (e) => {
    const newRadius = Number(e.target.value);
    setRadius(newRadius);
    if (center) {
      fetchLocationsInRadius(center.latitude, center.longitude, newRadius);
    }
  };

  const handleMapClick = (newCenter) => {
    setCenter(newCenter);
    fetchLocationsInRadius(newCenter.latitude, newCenter.longitude, radius);
  };

  const handleSend = () => {
    if (center) {
      fetchLocationsInRadius(center.latitude, center.longitude, radius);
    }
  };

  const handleTogglePersonal = () => {
    setShowPersonalLocations(!showPersonalLocations);
  };

  const handleToggleCourt = () => {
    setShowCourtLocations(!showCourtLocations);
  };

  const handleToggleAll = () => {
    setShowAllLocations(!showAllLocations);
  };

  return (
    <main className="container my-4">
      <div className="player-location-title text-center mb-4">
        <h1>Player Location Search</h1>
      </div>

      {/* Search for a location with autocomplete */}
    
      <div className="mb-3">
  {JSON.stringify({ lat: center?.latitude, lng: center?.longitude, radius: radius })}

        <div className="input-group">
          <input
            type="text"
            onChange={handleSearchChange}
            placeholder="Search for a location"
            className="form-control"
          />
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {/* Radius Slider and Send button */}
      <div className="filter mb-3">
        <div className="d-flex align-items-center">
          <label htmlFor="radius-slider" className="me-3 mb-0">
            Radius (miles): {radius}
          </label>
          <input
            type="range"
            id="radius-slider"
            min="1"
            max="50"
            value={radius}
            onChange={handleRadiusChange}
            className="form-range flex-grow-1 me-3"
          />
          <button className="btn btn-secondary" onClick={handleSend}>
            Send
          </button>
        </div>
      </div>

     {/* Toggles for different location types */}
     <div className="toggles mb-4 d-flex align-items-center">
        <div className="form-check form-switch me-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggleAll"
            checked={showAllLocations}
            onChange={handleToggleAll}
          />
          <label className="form-check-label" htmlFor="toggleAll">
            Show All Locations
          </label>
        </div>
        <div className="form-check form-switch me-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="togglePersonal"
            checked={showPersonalLocations}
            onChange={handleTogglePersonal}
          />
          <label className="form-check-label" htmlFor="togglePersonal">
            Show Personal Locations
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggleCourt"
            checked={showCourtLocations}
            onChange={handleToggleCourt}
          />
          <label className="form-check-label" htmlFor="toggleCourt">
            Show Court Locations
          </label>
        </div>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className="alert alert-info" role="alert">
          Loading locations...
        </div>
      )}

      {/* Error Message */}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}

      {/* Map Component */}
      <div className="mb-4">
        <MapComponent
          personalLocations={showPersonalLocations ? locations.personalLocations : []}
          courtLocations={showCourtLocations ? locations.courtLocations : []}
          allLocations={showAllLocations ? allLocations : []}
          radius={radius}
          onMapClick={handleMapClick}
          center={center}
        />
      </div>

      {/* Results Table with Collapsible Sections */}
      <div className="results-table">
        <h2 className="mb-3">Locations</h2>
        {(allLocations.length === 0 && locations.personalLocations.length === 0 && locations.courtLocations.length === 0) ? (
          <p>No locations found.</p>
        ) : (
          <div className="accordion" id="locationsAccordion">
            {/* All Locations Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingAll">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseAll"
                  aria-expanded="true"
                  aria-controls="collapseAll"
                >
                  All Locations ({allLocations.length})
                </button>
              </h2>
              <div
                id="collapseAll"
                className="accordion-collapse collapse show"
                aria-labelledby="headingAll"
                data-bs-parent="#locationsAccordion"
              >
                <div className="accordion-body">
                  {allLocations.length === 0 ? (
                    <p>No locations found.</p>
                  ) : (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Location Id</th>
                          <th>Player Name</th>
                          <th>Email</th>
                          <th>Location</th>
                          <th>Skill Level</th>
                          <th>Looking For</th>
                          <th>Coordinates</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allLocations.map((loc) => (
                          <tr key={loc.id} className={`location-row ${loc.location_type}`}>
                            <td>{loc.location_id}</td>
                            <td>{loc.player_name || 'N/A'}</td>
                            <td>{loc.email || 'N/A'}</td>
                            <td>{loc.location || 'N/A'}</td>
                            <td>{loc.skill_level || 'N/A'}</td>
                            <td>{loc.looking_for ? loc.looking_for.join(", ") : "N/A"}</td>
                            <td>
      {loc.coordinates ? (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${loc.coordinates.latitude},${loc.coordinates.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {loc.coordinates.latitude.toFixed(6)}, {loc.coordinates.longitude.toFixed(6)}
        </a>
      ) : (
        "N/A"
      )}
    </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            {/* Personal Locations Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingPersonal">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePersonal"
                  aria-expanded="false"
                  aria-controls="collapsePersonal"
                >
                  Personal Locations ({locations.personalLocations.length})
                </button>
              </h2>
              <div
                id="collapsePersonal"
                className="accordion-collapse collapse"
                aria-labelledby="headingPersonal"
                data-bs-parent="#locationsAccordion"
              >
                <div className="accordion-body">
                  {locations.personalLocations.length === 0 ? (
                    <p>No personal locations found.</p>
                  ) : (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                        <th>Location Id</th>
                          <th>Player Name</th>
                          <th>Email</th>
                          <th>Location</th>
                          <th>Skill Level</th>
                          <th>Looking For</th>
                          <th>Coordinates</th>
                        </tr>
                      </thead>
                      <tbody>
                        {locations.personalLocations.map((loc) => (
                          <tr key={loc.id} className={`location-row ${loc.location_type}`}>
                            <td>{loc.location_id || 'N/A'}</td>
                            <td>{loc.player_name || 'N/A'}</td>
                            <td>{loc.email || 'N/A'}</td>
                            <td>{loc.location || 'N/A'}</td>
                            <td>{loc.skill_level || 'N/A'}</td>
                            <td>{loc.looking_for ? loc.looking_for.join(", ") : "N/A"}</td> <td>
      {loc.coordinates ? (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${loc.coordinates.latitude},${loc.coordinates.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {loc.coordinates.latitude.toFixed(6)}, {loc.coordinates.longitude.toFixed(6)}
        </a>
      ) : (
        "N/A"
      )}
    </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>

            {/* Court Locations Section */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingCourt">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseCourt"
                  aria-expanded="false"
                  aria-controls="collapseCourt"
                >
                  Court Locations ({locations.courtLocations.length})
                </button>
              </h2>
              <div
                id="collapseCourt"
                className="accordion-collapse collapse"
                aria-labelledby="headingCourt"
                data-bs-parent="#locationsAccordion"
              >
                <div className="accordion-body">
                  {locations.courtLocations.length === 0 ? (
                    <p>No court locations found.</p>
                  ) : (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                        <th>Location Id</th>
                          <th>Player Name</th>
                          <th>Email</th>
                          <th>Location</th>
                          <th>Skill Level</th>
                          <th>Looking For</th>
                          <th>Coordinates</th>
                        </tr>
                      </thead>
                      <tbody>
                        {locations.courtLocations.map((loc) => (
                          <tr key={loc.id} className={`location-row ${loc.location_type}`}>
                            <td>{loc.location_id || 'N/A'}</td>
                            <td>{loc.player_name || 'N/A'}</td>
                            <td>{loc.email || 'N/A'}</td>
                            <td>{loc.location || 'N/A'}</td>
                            <td>{loc.skill_level || 'N/A'}</td>
                            <td>{loc.looking_for ? loc.looking_for.join(", ") : "N/A"}</td> <td>
      {loc.coordinates ? (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${loc.coordinates.latitude},${loc.coordinates.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {loc.coordinates.latitude.toFixed(6)}, {loc.coordinates.longitude.toFixed(6)}
        </a>
      ) : (
        "N/A"
      )}
    </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}

export default PlayerLocationSearch;
