import "./Sidebar.css";
import logo from "../../assets/images.png";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { HiLocationMarker } from "react-icons/hi";
import { FaSignOutAlt, FaShopify, FaQuestionCircle } from "react-icons/fa";

import { isAuthenticated } from "../apicalls/auth";
import { signout } from "../apicalls/auth";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const user = isAuthenticated();
  const location = useLocation();
  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <h2 className="text-center my-3">TheTennisPlan</h2>
        <i
          onClick={() => {
            closeSidebar();
          }}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>

      <div className="sidebar__menu">
        <Link className="alink" to="/">
          <div
            className={
              location.pathname === "/"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <AiOutlineHome className="sidebar_icon" />
            Dashboard
          </div>
        </Link>
        <Link className="alink" to="/Coaches">
          <div
            className={
              location.pathname === "/Coaches"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <BsFillPersonFill className="sidebar_icon" />
            Coaches
          </div>
        </Link>
        <Link className="alink" to="/players">
          <div
            className={
              location.pathname === "/players"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <BsFillPersonFill className="sidebar_icon" />
            Players
          </div>
        </Link>
        <Link className="alink" to="/transaction">
          <div
            className={
              location.pathname === "/transaction"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <RiMoneyEuroCircleFill className="sidebar_icon" />
            Transactions
          </div>
        </Link>
        <Link className="alink" to="/locations">
          <div
            className={
              location.pathname === "/locations"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <HiLocationMarker className="sidebar_icon" />
            Locations
          </div>
        </Link>
        <Link className="alink" to="/locations_search">
          <div
            className={
              location.pathname === "/locations_search"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <HiLocationMarker className="sidebar_icon" />
            Locations Search
          </div>
        </Link>
        <Link className="alink" to="/player_locations">
          <div
            className={
              location.pathname === "/player_locations"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <HiLocationMarker className="sidebar_icon" />
            Player Locations Search
          </div>
        </Link>
        <Link className="alink" to="/shopify">
          <div
            className={
              location.pathname === "/shopify"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <FaShopify className="sidebar_icon" />
            Shopify orders
          </div>
        </Link>
        <Link className="alink" to="/question">
          <div
            className={
              location.pathname === "/question"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <FaQuestionCircle className="sidebar_icon" />
            Questions
          </div>
        </Link>
        <Link className="alink" to="/filters">
          <div
            className={
              location.pathname === "/filters"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <FaQuestionCircle className="sidebar_icon" />
            filters
          </div>
        </Link>
        <Link className="alink" to="/survey_user_answers">
          <div
            className={
              location.pathname === "/survey_user_answers"
                ? "sidebar__link active"
                : "sidebar__link"
            }
          >
            <FaQuestionCircle className="sidebar_icon" />
            Survey User Answers
          </div>
        </Link>
        <div
          className="sidebar__link error signout"
          onClick={() => {
            signout(() => {
              window.location.reload();
            });
          }}
        >
          <FaSignOutAlt className="error sidebar_icon" />
          Signout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
