import "./style/DeletePopup.css";
import { GrClose } from "react-icons/gr";
import { isAuthenticated } from "../../apicalls/auth";
import { deleteCoach } from "../../apicalls/coaches";
import { deletePlayer } from "../../apicalls/players";
import { deleteLocation } from "../../apicalls/locations";
import { useEffect, useState } from "react";
import { deleteQuestion } from "../../apicalls/questions";

function DeletePopup({ setPopup, coach_id, player_id, location_id, question_id, onDeleteSuccess }) {
  const user = isAuthenticated();
  const [handle, setHandle] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (coach_id) {
      setHandle("Coach");
    } else if (player_id) {
      setHandle("Player");
    } else if (question_id) {
      setHandle("Question");
    } else {
      setHandle("Location");
    }
  }, [coach_id, player_id, question_id, location_id]);

  const OnDelete = async () => {
    try {
      if (coach_id) {
        await deleteCoach(coach_id, user.access_token);
        onDeleteSuccess(); // Notify parent component
        setPopup(false);
      }

      if (player_id) {
        await deletePlayer(player_id, user.access_token);
        onDeleteSuccess(); // Notify parent component
        setPopup(false);
      }

      if (location_id) {
        const data = await deleteLocation(location_id, user.access_token);
        if (!data.location) {
          setError(data.detail);
        } else {
          onDeleteSuccess(); // Notify parent component
          setError(false);
          setPopup(false);
        }
      }

      if (question_id) {
        await deleteQuestion(question_id, user.access_token);
        onDeleteSuccess(); // Notify parent component
        setPopup(false);
      }
    } catch (error) {
      setError(error);  // Display the error message from the backend
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="close-btn" onClick={() => setPopup(false)}>
          <GrClose />
        </div>
        <h1>Delete {handle}</h1>
        <div className="container">
          {error && <span className="error">{error}</span>}
          <div className="row">
            <span>Are you sure you want to delete this {handle}?</span>
            <div className="form-content col-lg-12">
              <button onClick={() => setPopup(false)}>Cancel</button>
            </div>
            <div className="form-content col-lg-12">
              <button onClick={OnDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePopup;
