import { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapComponent.css';

// Replace this with your actual Mapbox token
mapboxgl.accessToken = 'pk.eyJ1Ijoic2FoaWxrYXNoeWFwNjQiLCJhIjoiY2tkNDFxYmtoMXQxbjJybXlleGxhejE4MSJ9.LGSnbgHFFcuBE8wXHxIMiA';

function MapComponentForCoach({ locations }) {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!mapboxgl.accessToken) {
      console.error("Mapbox access token is required.");
      return;
    }

    // Initialize the map
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-118.2437, 34.0522], // Initial center
      zoom: 2, // Zoom level for showing multiple markers
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Add markers for each location
    locations.forEach((location) => {
      const latitude = parseFloat(location.latitude);
      const longitude = parseFloat(location.longitude);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        new mapboxgl.Marker()
          .setLngLat([longitude, latitude])
          .setPopup(new mapboxgl.Popup().setText(location.location)) // Optional popup with location name
          .addTo(mapRef.current);
      } else {
        console.warn(`Invalid coordinates for location ID ${location.id}`);
      }
    });

    // Cleanup function to remove map on unmount
    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [locations]);

  // Function to fly to the next location in the list
  const flyToLocation = () => {
    if (locations.length === 0) return;

    const nextLocation = locations[currentIndex];
    const latitude = parseFloat(nextLocation.latitude);
    const longitude = parseFloat(nextLocation.longitude);

    if (!isNaN(latitude) && !isNaN(longitude)) {
      mapRef.current.flyTo({
        center: [longitude, latitude],
        essential: true,
        zoom: 14, // Zoom in to the location
      });
      
      // Update the index to loop through locations
      setCurrentIndex((prevIndex) => (prevIndex + 1) % locations.length);
    }
  };

  return (
    <div className="map-component" style={{ position: 'relative' }}>
      <div id="map-container" ref={mapContainerRef} style={{ width: '100%', height: '500px' }} />
      <button onClick={flyToLocation} style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
        Fly to Next Location
      </button>
    </div>
  );
}

export default MapComponentForCoach;
